@import "../../sass/global.module";

.button1-style {
  min-width: 4em;
  padding: 0.3em 0.5em;
  font-family: $work-font-family;
  font-size: $font-size-p-sm;
  font-weight: $thin;
  border: 1px solid;
  border-radius: 0.375rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  transition: width 0.3s, border-radius 0.3s;
  &:hover {
    cursor: none;
    border-radius: 1rem;
  }
}

.about-contact-btn {
  height: 2em;
  border: 1px solid;
  font-size: $font-size-p-m;
  font-weight: $extra-bold;
  width: 8em;
  align-self: center;
  transition: border-radius 0.3s;
  &:hover {
    border-radius: 2rem;
  }
}

// @media screen and (max-width: 600px) {
//   .button1-style {
//     flex-direction: column-reverse;
//     gap: 0.1em;
//     font-size: $font-size-p-m;
//     i {
//       font-size: 1.2em;
//     }
//   }
// }
