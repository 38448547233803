@import "../../sass/global.module";

.play {
  cursor: none;
  height: $full-vh+20;
  width: $full-vw;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(2rem, 1fr));
  grid-template-rows: repeat(auto-fill, minmax(2rem, 1fr));
  grid-gap: 1rem;
  overflow: hidden;
  position: relative;
  > div {
    position: relative;
    &::before {
      // for apsect ratio
      content: "";
      display: block;
      padding-bottom: 100%;
    }
  }
  p {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 25vh;
    font-size: $font-size-h2;
    font-weight: $extra-thin;
    font-family: $work-font-family;
    animation: wiggle 2s alternate-reverse infinite ease-in-out;
    z-index: 0;
  }
}

@keyframes wiggle {
  from {
    top: 25%;
  }
  to {
    top: 28%;
  }
}

.animate-play {
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 100%;
    height: 100%;
    // width: fit-content;
    // height: fit-content;
  }
  circle {
    opacity: 1;
    &:hover {
      opacity: 1;
    }
    &:not(:hover) {
      transition: 3s;
    }
  }
}
