@import "../../sass/global.module";

#contact {
  position: relative;
  padding: $padding-full-vw;
  width: 100%;
  height: $full-vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: $work-font-family;
  .contact-intro {
    height: 40vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2em;
    h2 {
      width: fit-content;
      text-align: center;
      border-bottom: 2px double;
      line-height: 0.95;
    }
    p {
      width: 60%;
      font-size: $font-size-p-m;
      font-weight: $extra-thin;
      text-align: center;
    }
  }
  .contact-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2em;
    font-size: $font-size-p-m;
    position: relative;
    div span {
      font-weight: $extra-thin;
      text-align: center;
    }
    .contact-email {
      font-weight: $medium;
    }
    .resume-link {
      position: relative;
      font-weight: $extra-thin;
      border: 1px solid;
      border-radius: 0.2em;
      padding: 0.2em 0.5em;
      transition: 0.3s;
      animation: move-button 0.8s infinite alternate-reverse ease-in-out;
      &:hover {
        border-radius: 2em;
        transition: 0.3s;
        cursor: none;
      }
    }
    @keyframes move-button {
      from {
        top: 0px;
      }
      to {
        top: -10px;
      }
    }
    .socials {
      // width: 70%;
      padding: 1em 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 2em;
      margin-bottom: 2em;
      padding: 1em 3em;
      border-radius: 0.375rem;
      h3 {
        font-family: $work-font-family;
        font-weight: $extra-thin;
        font-size: $font-size-h3;
        border-bottom: 1px solid;
      }
      .social-icons {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 4rem;
        transition: 0.3s;
      }
      .icons {
        transition: 0.3s;
        font-size: 2.5em;
      }
      i:hover {
        // cursor: pointer;
        cursor: none;
      }
    }
  }
}

@media screen and (max-width: 820px) {
  #contact {
    .contact-info {
      .socials {
        h3 {
          font-size: $font-size-h3;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  #contact {
    margin-top: 2em;
    height: 100vh + 5;
    .contact-intro {
      p {
        width: 100%;
        font-size: $font-size-p-m;
      }
    }
    .contact-info {
      div {
        text-align: center;
      }
      .socials {
        width: 100vw;
        border-radius: 0;
        gap: 1em;
        h3 {
          font-size: $font-size-h2;
        }
        .social-icons {
          gap: 2em;
        }
        .icons {
          font-size: 2em;
          transition: 0.3s;
        }
      }
    }
  }
}
