@import "../../sass/global.module";

#about {
  width: 100%;
  padding: $padding-full-vw + 1;
  padding-bottom: 5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2em;
  font-family: $work-font-family;
  p {
    font-size: $font-size-p-m;
    font-weight: $extra-thin;
    text-align: start;
  }
  a {
    border-bottom: 1px solid;
    &:hover {
      cursor: none;
    }
  }
  .about-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3em;
    h2 {
      width: fit-content;
      text-align: center;
      border-bottom: 2px double;
      line-height: 0.95;
    }
  }
  .about-section {
    display: flex;
  }
  .section-1 {
    width: 100%;
    flex-direction: row;
    // align-items: center;
    gap: 3em;
    transition: flex-direction 0.3s;
    .section-1-text {
      width: 50%;
    }
    .img-container {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: width 0.3s;
    }
    img {
      width: 70%;
      height: auto;
      border-radius: 50%;
      margin-bottom: 5em;
    }
  }
  .section-2 {
    flex-direction: row;
    gap: 3em;
    max-height: 110vh;
    .section-2-text {
      width: 50%;
    }
    .section-2-pretty {
      width: 50%;
      display: grid;
      grid-template-columns: repeat(auto-fill, 2rem);
      grid-template-rows: repeat(auto-fill, 2rem);
      position: relative;
      overflow: hidden;
      .pretty-line {
        height: 2rem;
        width: 2rem;
        opacity: 0;
        will-change: transform;
      }
    }
  }
  .section-3 {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    p {
      padding: 0.5em 1em;
      border-radius: 0.375rem;
      font-size: $font-size-p-lg;
      width: 80%;
      font-weight: $thin;
      text-align: center;
    }
  }
}

@media screen and (max-width: 1200px) {
  #about {
    .section-1 {
      flex-direction: column-reverse;
      align-items: center;
      .section-1-text {
        width: 100%;
        p {
          text-align: center;
        }
      }
      .img-container {
        width: 60%;
        img {
          margin-bottom: 0;
        }
      }
    }
    .section-2 .section-2-text {
      height: fit-content;
    }
  }
}

@media screen and (max-width: 820px) {
  #about {
    .section-1 {
      .img-container {
        width: 70%;
      }
    }
    .section-2 {
      flex-direction: column;
      max-height: fit-content;
      .section-2-text {
        width: 100%;
        p {
          text-align: center;
        }
      }
      .section-2-pretty {
        width: 100%;
        height: 50vh;
        overflow: hidden;
      }
    }
    .section-3 {
      flex-direction: column;
      p {
        width: 70%;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  #about {
    padding: 2em 0 2em 0;
    .about-text {
      width: 80%;
      transition: 0.3s;
      gap: 1em;
      p {
        font-size: $font-size-p-m;
        text-align: center;
        transition: 0.3s;
      }
    }
    .section-1 {
      .section-1-text {
        p {
          text-align: center;
        }
      }
      .img-container {
        width: 100%;
      }
    }
    .section-2 {
      flex-direction: column;
      max-height: fit-content;
      .section-2-text {
        width: 100%;
      }
      .section-2-pretty {
        width: 100%;
        height: 50vh;
        overflow: hidden;
      }
    }
    .section-3 {
      flex-direction: column;
      p {
        width: 100vw;
        font-size: $font-size-p-lg;
        text-align: center;
        padding: 1em;
      }
      div {
        width: 100%;
      }
    }
  }
}

