//// Variables
// Fonts
$outfit-font-family: "Outfit", sans-serif;
$work-font-family: "Work Sans", sans-serif;
$font-size-h1: calc(25px + 5vw);
$font-size-h2: calc(20px + 3vw);
$font-size-h3: calc(18px + 2vw);
$font-size-h4: calc(15px + 1vw);
$font-size-p-lg: calc(18px + 0.8vw);
$font-size-p-m: calc(15px + 0.5vw);
$font-size-p-ms: calc(12px + 0.5vw);
$font-size-p-sm: calc(12px + 0.3vw);
$font-size-p-xs: calc(10px + 0.3vw);
$extra-thin: 300;
$thin: 300;
$medium: 500;
$bold: 800;
$extra-bold: 900;

// Colors
$background-color: #101010;
$dark-background-color: #202020;
$text-dark-bg: #f9f9f9;
$light-background-color: #f9f9f9;
$text-light-bg: #202020;

// Padding
$padding-full-vw: 2em;

// Margins

// Widths
$full-vw: 100%;

// Heights
$full-vh: 100vh;

$mode: "light";

@mixin mode-colors($mode) {
  @if $mode== "light" {
    background-color: $light-background-color;
    color: $text-light-bg;
    transition: 0.2s;
    a {
      color: $text-light-bg;
      text-decoration: none;
    }
    button {
      background-color: $light-background-color;
      color: $text-light-bg;
    }
    .navigation .material-symbols-rounded {
      color: $text-light-bg;
    }
    .animate-play circle {
      fill: #c1d20076;
      // opacity: 0.5;
    }
    #about h2 {
      font-size: $font-size-h2;
      font-weight: $extra-bold;
      font-family: $work-font-family;
      color: $text-light-bg;
    }
    #about {
      circle {
        fill: $text-light-bg;
      }
    }
    .section-3 p {
      color: fade-out($color: $text-light-bg, $amount: 0.2);
      background-color: #eaeaea;
    }
    #skills h2,
    #contact h2 {
      font-size: $font-size-h2;
      font-weight: $extra-bold;
      font-family: $work-font-family;
      text-align: center;
      color: $text-light-bg;
    }
    #projects {
      h2 {
        color: $text-light-bg;
      }
      .info-container {
        border: 1px solid $light-background-color;
      }
    }
    .skill-name {
      // background-color: $dark-background-color;
      // color: $text-dark-bg;
    }
    .skill {
      // background-color: $dark-background-color;
      color: $text-light-bg;
      filter: contrast(0) brightness(0.5);
    }
    #contact .contact-info {
      .socials {
        background-color: #eaeaea;
      }
      i:hover {
        color: fade-out($color: $text-light-bg, $amount: 0.2);
      }
    }
  } @else {
    background-color: $dark-background-color;
    color: $text-dark-bg;
    transition: 0.2s;
    a {
      color: $text-dark-bg;
      text-decoration: none;
    }
    button {
      background-color: $dark-background-color;
      color: $text-dark-bg;
    }
    .navigation .material-symbols-rounded {
      color: $text-dark-bg;
    }
    .animate-play circle {
      // opacity: 0.4;
      fill: #fdc3d277;
    }
    #about h2 {
      font-size: $font-size-h2;
      font-weight: $extra-bold;
      font-family: $work-font-family;
      color: $text-dark-bg;

    }
    #about {
      circle {
        fill: $text-dark-bg;
      }
    }
    .section-3 p {
      color: fade-out($color: $text-dark-bg, $amount: 0.2);
      background-color: lighten($color: $dark-background-color, $amount: 3);
    }
    #skills h2,
    #contact h2 {
      font-size: $font-size-h2;
      font-weight: $extra-bold;
      font-family: $work-font-family;
      text-align: center;
      color: $text-dark-bg;
    }
    #projects {
      h2 {
        color: $text-dark-bg;
      }
      .info-container {
        border: 1px solid $dark-background-color;
      }
    }
    .skill-name {
      // background-color: $light-background-color;
      // color: $text-light-bg;
    }
    .skill {
      filter: contrast(0) brightness(2);
      color: $text-dark-bg;
    }
    #contact .contact-info {
      .socials {
        // background-color: lighten($color: $dark-background-color, $amount: 1);
        background-color: lighten($color: $dark-background-color, $amount: 3);
      }
      i:hover {
        color: fade-out($color: $text-dark-bg, $amount: 0.3);
      }
    }
  }
}

:export {
  mode: $mode;
}
