@import "../../sass/global.module";

#skills {
  margin-top: 5em;
  height: $full-vh + 10;
  padding: $padding-full-vw;
  display: flex;
  flex-direction: column;
  gap: 4em;
  justify-content: center;
  align-items: center;
  h2{
    width: fit-content;
    text-align: center;
    border-bottom: 2px double;
    line-height: 0.95;
  }
  ul {
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    grid-template-rows: auto auto auto;
    gap: 4em;
    padding-left: 0px;
    list-style-type: none;
    transition: 0.3s;
  }
  img {
    background-color: white;
  }
}

#skills .skill {
  justify-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
  width: fit-content;
  position: relative;
  transition: filter 0.4s;
  .skill-name {
    font-size: $font-size-p-sm;
    font-weight: $extra-thin;
    font-family: $work-font-family;
    padding: 0.1em 0.5em;
  }
  &:hover {
    filter: none;
    .skill-name {
      visibility: visible;
      opacity: 1;
      transition: 0.4s;
    }
  }
}

// @media screen and (max-width: 1055px) {
//   #skills {
//     ul {
//       grid-template-columns: repeat(5, auto);
//     }
//   }
// }

@media screen and (max-width: 820px) {
  #skills {
    height: 100%;
    ul {
      grid-template-columns: repeat(4, 1fr);
      transition: 0.3s;
    }
  }
}

@media screen and (max-width: 620px) {
  #skills {
    margin-top: 3em;
    ul {
      grid-template-columns: repeat(3, 1fr);
      gap: 1em;
    }
  }
  .skill {
    transform: scale(0.9);
    transition: 0.3s;
  }
}
