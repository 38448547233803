@import "../../sass/global.module";

.jumbotron {
  height: $full-vh+2;
  width: $full-vw;
  display: grid;
  grid-template-columns: repeat(32, 2rem);
  grid-template-rows: repeat(17, 2rem);
  grid-gap: 1rem;
  overflow: hidden;
  > div {
    position: relative;
    &::before {
      // for apsect ratio
      content: "";
      display: block;
      padding-bottom: 100%;
    }
  }
}

.animate {
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 100%;
    height: 100%;
  }
  circle {
    opacity: 1;
    fill: #8c8c8c00;
    // &:hover {
    //   opacity: 1;
    // }
    // &:not(:hover) {
    //   transition: 3s;
    // }
  }
}

.intro {
  position: absolute;
  top: 25vh;
  // color: $text-dark-bg;
  padding: $padding-full-vw;
  text-align: center;
  :first-child {
    margin: 0;
    font-weight: $extra-thin;
  }
  h2 {
    position: relative;
    font-weight: $bold;
    font-size: $font-size-h1;
    margin: 0;
    z-index: 2;
  }
  p {
    font-family: $work-font-family;
    font-weight: $extra-thin;
    // font-weight: $bold;
    font-size: $font-size-p-lg;
    position: relative;
    z-index: 2;
  }
}

.clicker-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  animation: moveupdown 1s infinite alternate-reverse ease-in-out;
  &:hover {
    cursor: none;
  }
  .arrow-clicker {
    // cursor: pointer;
    p {
      margin-bottom: -36px;
      font-size: $font-size-p-sm;
      font-weight: $bold;
    }
  }
}

.down-arrow {
  border: solid;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 30px;
  // cursor: pointer;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

@keyframes moveupdown {
  from {
    top: 8vh;
  }
  to {
    top: 10vh;
  }
}

@media screen and (max-width: 600px) {
  .jumbotron {
    height: $full-vh;
  }
}