@import "../../sass/global.module";

.projects {
  position: relative;
  margin-top: 2em;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 1em;
  padding: $padding-full-vw;
  h2 {
    width: fit-content;
    font-size: $font-size-h2;
    font-weight: $extra-bold;
    font-family: $work-font-family;
    border-bottom: 2px double;
    line-height: 0.95;
  }
  .project-text {
    font-size: $font-size-p-m;
    font-weight: $extra-thin;
    font-family: $work-font-family;
    text-align: center;
    width: 85%;
  }
}

.projects-container {
  margin-top: 3em;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  row-gap: 5em;
  flex-wrap: wrap;
}

.project {
  width: 45%;
  height: max-content;
  transition: width 0.3s;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 2em;
  .project-title {
    display: flex;
    flex-direction: column;
    gap: 1em;
    h3 {
      font-family: $work-font-family;
      font-size: $font-size-h4;
      font-weight: $thin;
      text-align: left;
    }
    .info-container {
      position: relative;
      border-radius: 1rem;
      overflow: hidden;
      &:hover {
        animation: fade-in 0.3s ease-in forwards;
      }
      img {
        position: relative;
        height: auto;
        width: 100%;
        z-index: 1;
        &:hover {
          animation: fade-out 0.3s ease-in forwards;
        }
      }
      .project-info {
        height: 100%;
        width: 100%;
        padding: 1em;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        font-family: $work-font-family;
        font-size: $font-size-p-ms;
        font-weight: $extra-thin;
        text-align: justify;
        position: absolute;
        transition: position 0.3s;
        top: 0;
        z-index: 0;
        .tech-stack {
          padding: 0;
          margin: 0;
          list-style-type: none;
          display: flex;
          flex-direction: row;
          justify-content: center;
          gap: 1em;
        }
      }
    }

    @keyframes fade-out {
      to {
        opacity: 0;
      }
    }

    @keyframes fade-in {
      to {
        border: 1px solid;
      }
    }

    .project-buttons {
      display: flex;
      flex-direction: row;
      gap: 2em;
      align-self: center;
    }
  }
}

// .other-projects .project{
//   width: 30%;
// }

.project-modal {
  font-weight: $extra-thin;
  cursor: auto;
  &.dark {
    @include mode-colors("dark");
  }
  &.light {
    @include mode-colors("light");
  }
  .material-symbols-rounded {
    font-variation-settings: "FILL" 0, "wght" 100, "GRAD" -25, "opsz" 48;
    cursor: pointer;
  }
}

.project-modal-header {
  border-bottom: 1px solid;
  .modal-title {
    font-weight: $extra-thin;
    width: 100%;
    text-align: center;
  }
}

@media screen and (max-width: 1155px) {
  .project {
    width: 60%;
    .project-title .info-container .project-info {
      font-size: $font-size-p-m;
    }
  }
}

@media screen and (max-width: 820px) {
  .project {
    width: 70%;
  }
}

// @media screen and (max-width: 600px) {
//   #projects #projects-container .project {
//     width: 90%;
//   }
// }

@media screen and (max-width: 600px) {
  .projects {
    .project-text {
      font-size: $font-size-p-m;
      font-weight: $extra-thin;
      font-family: $work-font-family;
      text-align: center;
      width: 100%;
    }
  }
  .projects-container {
    margin-top: 0;
    row-gap: 3em;
  }
  .projects .project {
    width: 90%;
    margin: 2em 0;
    .project-title {
      align-items: center;
      h3 {
        font-size: $font-size-h3;
      }
      .info-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 0;
        &:hover {
          animation: none;
        }
        img {
          border-radius: 0.2rem;
        }
        img:hover {
          animation: none;
        }
        .project-info {
          position: relative;
          text-align: center;
          z-index: 1;
          height: fit-content;
          margin: 1em 0;
          padding: 0;
        }
      }
    }
  }
}
